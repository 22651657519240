const columns = [
  {
    readableName: 'Order ID',
    apiKey: 'id',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Shipment ID',
    apiKey: 'shipmentId',
    sortable: true,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Order Type',
    apiKey: 'type',
    sortable: false,
    searchable: true,
    searchType: 'dropdown',
  },
  {
    readableName: 'Quoted By',
    apiKey: 'quotedBy',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Size SKU',
    apiKey: 'posSizeSku|menuSizeSku',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Size (name)',
    apiKey: 'posSize|menuSize',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Paper SKU',
    apiKey: 'paperLaminateSku',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Paper (name)',
    apiKey: 'paperLaminate',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Quantity',
    apiKey: 'quantity',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Brand',
    apiKey: 'brand',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Market',
    apiKey: 'market',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'State',
    apiKey: 'state',
    sortable: false,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Printer Error',
    apiKey: 'printerError',
    sortable: false,
    searchable: false,
    searchType: 'text',
  },
  {
    readableName: 'Printer',
    apiKey: 'printerName',
    sortable: true,
    searchable: true,
    searchType: 'text',
  },
  {
    readableName: 'Print Status',
    apiKey: 'printStatus',
    sortable: false,
    searchable: true,
    searchType: 'dropdown',
  },
  {
    readableName: 'Order Status',
    apiKey: 'orderStatus',
    sortable: false,
    searchable: false,
    searchType: 'text',
  },
  {
    readableName: 'Completed',
    apiKey: 'completedUtcDate',
    sortable: true,
    searchable: true,
    searchType: 'dateRange',
  },
  {
    readableName: 'Sent To Print',
    apiKey: 'sentToPrinterUtcDate',
    sortable: true,
    searchable: true,
    searchType: 'dateRange',
  },
];
export default columns;
